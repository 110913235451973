import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './components/redux/store';
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";

import { ToastContainer } from 'react-toastify';
const App = lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense
      //  fallback={
      //       <div className="w-100 vh-100 main_app d-flex justify-content-center align-items-center">
      //                           <Spinner animation="border" variant="success" />

      //       </div>
      //     }
  >
    <Provider store={store}>
      <BrowserRouter>
        <React.StrictMode>
          <App />
          <ToastContainer newestOnTop />
        </React.StrictMode>
      </BrowserRouter>
    </Provider>
  </Suspense>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
